import React, { Fragment } from 'react';
import CardHorizontal from '../../components/card-horizontal/CardHorizontal.component';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';
const ThesisList = () => (
	<Fragment>
	
	<PageTitleBanner 
	backgroundImage={IMAGES.banner4}
	titleText="- Thesis List -"
	/>
	<CardHorizontal />

	</Fragment>
);

export default ThesisList;
