import React from 'react';
import './logoSlider.style.scss';

const LogoSlider = ({items}) => {


  
  return (
    <div
  className="slider"
  style={{
    '--width': '200px',
    '--height': '100px',
    '--quantity': items.length
  }}
>
{/*
        <div className='list'>
            <div className='item' style={{'--position': 1}}><img src={IMAGES.emerald} alt='' /></div>
            <div className='item' style={{'--position': 2}}><img src={IMAGES.jstor} alt='' /></div>
            <div className='item' style={{'--position': 3}}><img src={IMAGES.ebsco} alt='' /></div>
            <div className='item' style={{'--position': 4}}><img src={IMAGES.springer} alt='' /></div>
            <div className='item' style={{'--position': 5}}><img src={IMAGES.citizensCharter} alt='' /></div>
            <div className='item' style={{'--position': 6}}><img src={IMAGES.idCard} alt='' /></div>
            <div className='item' style={{'--position': 7}}><img src={IMAGES.remote} alt='' /></div>
            <div className='item' style={{'--position': 8}}><img src={IMAGES.thesis2} alt='' /></div>
            <div className='item' style={{'--position': 9}}><img src={IMAGES.bookYourSlot} alt='' /></div>
            
        </div>
        */}
        
        <div className='list'>
        {items.map((item, index) => (
          <div
            key={index}
            className="item"
            style={{
              '--position': index + 1 ,
            }}
          >
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <img src={item.img} alt={item.name} />
          </a>
          </div>
        ))}
        </div>
    </div>
  );
};

export default LogoSlider;
