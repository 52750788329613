
import React from "react";
import "./HorizontalList.style.scss";


  
const HorizontalList  = ({ icon, title, description, link }) => (
   
    
    <div className="card">
   
      <div className="icon">
        {icon}
      </div>
      <h2 className="title">{title}</h2>
      <p className="description">{description}</p>
      <a href={link} className="learn-more">
        LEARN MORE  <span className="arrow">→</span>

      </a>
    </div>
);

export default HorizontalList;

   