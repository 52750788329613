import React, { useRef } from 'react';
import './StylishCard.style.scss';

const StylishCard = ({ cards, bannerBackgroundColor }) => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  
  return (
    <div className='banner_section'>
      <div className="styleCard_container" style={{ backgroundColor: bannerBackgroundColor }}>
        
      
        <div className="games" ref={scrollRef}>

        
          {cards.map((game, index) => (
            <div className="styleBanner_wrapper" key={index}>
              <div className="styleBanner">
                <img
                  src={game.bannerImage}
                  alt={`Banner for ${game.title}`}
                  className="styleBanner__image logo"
                />
              </div>
              <div className="styleCard__wrapper">
                <div className="styleCard">
                  <div className="styleCard__info">
                    <img 
                      src={game.bannerImage}
                      alt={`Avatar for ${game.title}`}
                      className="styleCard__avatar"
                    />
                    <div>
                      <span>{game.title}</span>
                      <p>{game.description}</p>
                    </div>
                  </div>
                  <a href={game.link} className='button_open'>Open</a>
                </div>
              </div>
            </div>
          ))}
          
        </div>
       
        <div className='arrowButtons'>
        <button onClick={scrollLeft} className="scroll-button">←</button>
        <button onClick={scrollRight} className="scroll-button">→</button>
      
        </div>
        </div>
       
    </div>
    
  );
};

export default StylishCard;
