import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './LibraryHours.style.scss';

const LibraryHours = ({ libraryTime }) => {
  const [isOpenNow, setIsOpenNow] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  const convertTimeToDate = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
    if (modifier === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }
    return new Date().setHours(hours, minutes, 0);
  };

  useEffect(() => {
    const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
    const todayTiming = libraryTime.find(item => item.day === today);

    if (todayTiming && todayTiming.time !== 'Closed') {
      const [openTime, closeTime] = todayTiming.time.split(' - ');
      const open = convertTimeToDate(openTime);
      const close = convertTimeToDate(closeTime);
      const now = new Date().getTime();

      console.log(open+" "+close+" "+now)

      if (now >= open && now <= close) {
        setIsOpenNow(true);
      } else {
        setIsOpenNow(false);
      }
    } else {
      setIsOpenNow(false);
    }
  }, [libraryTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (date) => {
    let hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return { hours: hours.toString().padStart(2, '0'), minutes, seconds, ampm };
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  const formatTimeZone = (date) => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const { hours, minutes, seconds, ampm } = formatTime(currentTime);

  const hourPercentage = (parseInt(hours) / 12) * 100;
  const minutePercentage = (parseInt(minutes) / 60) * 100;
  const secondPercentage = (parseInt(seconds) / 60) * 100;

  return (
    <div className="library-container">
      <div className="clock-container">
        <div className="circle-clock">
          <div className="circle-border" style={{ background: `conic-gradient(#ff007f ${hourPercentage}%, transparent 0)` }}>
            <div className="circle-text">
              <span className="number">{hours}</span>
              <span className="label">HOURS</span>
            </div>
          </div>
          <div className="circle-border" style={{ background: `conic-gradient(#ffda00 ${minutePercentage}%, transparent 0)` }}>
            <div className="circle-text">
              <span className="number">{minutes}</span>
              <span className="label">MINUTES</span>
            </div>
          </div>
          <div className="circle-border" style={{ background: `conic-gradient(#00ff7f ${secondPercentage}%, transparent 0)` }}>
            <div className="circle-text">
              <span className="number">{seconds}</span>
              <span className="label">SECONDS</span>
            </div>
          </div>
        </div>
        <p className="clock-ampm">{ampm}</p>
        <p className="clock-date">{formatDate(currentTime)}</p>
        <p className="clock-timezone">{formatTimeZone(currentTime)}</p>
      </div>

      <div className="library-timings-container">
        <div className={`status ${!isOpenNow ? 'closed' : ''}`}>
          <span className="status-icon">
            {isOpenNow ? <FaCheckCircle /> : <FaTimesCircle />}
          </span>
          {isOpenNow ? 'Library is open now' : 'Library is closed now'}
        </div>

      
        <div className="timing-list">
          {libraryTime && libraryTime.length > 0 ? (
            libraryTime.map((item, index) => (
              <div key={index} className={`timing-item ${item.time === 'Closed' ? 'highlight' : ''}`}>
                <span className="day">{item.day}</span>
                <span className="time">{item.time}</span>
              </div>
            ))
          ) : (
            <p>No timings available</p>
          )}
        </div>

        <p className="holiday-note">
          ** Library will be closed on all public holidays.
        </p>
      </div>
    </div>
  );
};

export default LibraryHours;
