import React, { Fragment } from 'react';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';
import PrivacyPolicy from '../../components/privacy_policy/privacyPolicy.component';

const PrivacyPolicyPage = () => (
	<Fragment>
	<PageTitleBanner 
	backgroundImage={IMAGES.banner2}
	titleText="- Privacy Policy -"
	/>
    <PrivacyPolicy />
	</Fragment>
);

export default PrivacyPolicyPage;
