import {
  faAngleDown,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import application from "../../assets/PDF/forms/Application ID Card.pdf";
import clearance from "../../assets/PDF/forms/গ্রন্থাগার ছাড়পত্র.pdf";
import teacherForm from "../../assets/PDF/forms/শিক্ষকদের জন্য.jpg";
import juLogo from "../../assets/images/logo-ju main.jpg";
import "./Header.style.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [activeDropdowns, setActiveDropdowns] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 1000;
      setMobileView(isMobile);
      if (!isMobile) {
        setMenuOpen(false); // Close mobile menu when switching to desktop
        setActiveDropdowns([]); // Reset dropdowns
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  const handleDropdownToggle = (index, e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setActiveDropdowns((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <nav>
      <div className="container row">
        <div className="logo">
        <a href="https://juniv.edu" >
          <img src={juLogo} alt="Logo" />
          </a>
        </div>
        <div className="mobile_btn" onClick={toggleMenu}>
          <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} className="fa-xl" />
        </div>
        <div className={`main_menu ${menuOpen && mobileView ? "active" : ""}`}>
          <ul>
            <li><a href="/">Home</a></li>
           {/*} <li><a href="https://juniv.edu">JU Home</a></li> */}
            
            <li><a href="/services">Services</a></li>

            {/* Resources Dropdown */}
            <li className="has_dropdown">
              <a href="#" onClick={(e) => handleDropdownToggle(0, e)}>
                Resources <FontAwesomeIcon icon={faAngleDown} />
              </a>
              <ul className={`sub_menu ${activeDropdowns[0] ? "active" : ""}`}>
                <li><a href="/" target="_blank" rel="noreferrer">Resources 1</a></li>
                <li><a href="/" target="_blank" rel="noreferrer">Resources 2</a></li>
                <li><a href="/" target="_blank" rel="noreferrer">Resources 3</a></li>
              </ul>
            </li>

            <li><a href="/a-z-database">A-Z DB</a></li>
            <li><a href="/">Digital Library</a></li>

            {/* Downloads Dropdown */}
            <li className="has_dropdown">
              <a href="#" onClick={(e) => handleDropdownToggle(1, e)}>
                Downloads <FontAwesomeIcon icon={faAngleDown} />
              </a>
              <ul className={`sub_menu ${activeDropdowns[1] ? "active" : ""}`}>
                <li><a href={application} target="_blank" rel="noreferrer">Application ID Card</a></li>
                <li><a href={clearance} target="_blank" rel="noreferrer">Library Clearance</a></li>
                <li><a href={teacherForm} target="_blank" rel="noreferrer">University ID Card</a></li>
              </ul>
            </li>
            <li><a href="/">About</a></li>

            <li><a href="https://opac.juniv.edu">Log In</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
