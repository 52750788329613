import React, { useState } from 'react';
import './HorizontalBox.style.scss';

const HorizontalBox = ({ bannerData }) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredData, setFilteredData] = useState(bannerData);

  const handleFilterChange = (e) => {
    const keyword = e.target.value.toLowerCase();
    setSearchKeyword(keyword);

    const filtered = bannerData.filter((item) =>
      item.title.toLowerCase().includes(keyword) ||
      item.description.toLowerCase().includes(keyword)
    );
    setFilteredData(filtered);
  };

  return (
    <div className="banner-container">
      <h1 className="section-title">Explore Academic Organizations</h1>

      <div className="filter-section">
        <input
          type="text"
          placeholder="Filter by keyword or organization..."
          value={searchKeyword}
          onChange={handleFilterChange}
        />
      </div>

      <div className="banner-list">
        {filteredData.map((item) => (
          <div key={item.id} className="banner">
            <div className="banner-header">
              <h2>{item.title}</h2>
            </div>
            <div className="banner-body">
              <p>{item.description}</p>
              
              
            </div>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
                <button className="visit-btn">Visit Site</button>
              </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalBox;
