import React, { useEffect, useState } from "react";
import { FaBook, FaFilePdf } from 'react-icons/fa'; // Importing icons
import "./CardHorizontal.style.scss";
const thesisData = [
{
      accessionNo: "TPH-1",
      author: "ইদরিস আলী মুহম্মদ",
      title: "বাংলাদেশের উপন্যাস সাহিত্যে মধ্যবিত্ত শ্রেণী: ১৯৪৭-১৯৭০",
      degree: "Ph.D.",
      department: "Bangla",
      year: "১৯৮৩",
      totalPage: 292,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-2",
      author: "John Doe",
      title: "Remot sensing applications to coastal morphological investigations in bangladesh",
      degree: "Ph.D.",
      department: "Geography & Environment",
      year: "১৯৮৩",
      totalPage: "227,14,8",
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-3",
      author: "Jane Smith",
      title: "Landless in rural economy a case study of a few selected villages in bangladesh",
      degree: "Ph.D.",
      department: "Economics",
      year: "১৯৮৪",
      totalPage: 511,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-4",
      author: "ইদরিস আলী মুহম্মদ",
      title: "নজরুল কাব্যে সাময়িকতা",
      degree: "Ph.D.",
      department: "Bangla",
      year: "১৯৮৫",
      totalPage: 383,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-5",
      author: "Jane Doe",
      title: "A study on wood plastic combination of some bangladesh woods",
      degree: "Ph.D.",
      department: "Chemistry",
      year: "১৯৯১",
      totalPage: 215,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-6",
      author: "John Smith",
      title: "Settlement pattern in bangladesh",
      degree: "Ph.D.",
      department: "Geography & Environment",
      year: "১৯৯১",
      totalPage: 275,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-7",
      author: "Jane Roe",
      title: "Comparative study analysis of groups of experiments",
      degree: "Ph.D.",
      department: "Statistics",
      year: "১৯৯১",
      totalPage: 309,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-8",
      author: "John Doe",
      title: "Rural periodic Market in bangladesh : A case study of four thanas",
      degree: "Ph.D.",
      department: "Geography & Environment",
      year: "১৯৯৩",
      totalPage: 311,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-9",
      author: "Jane Smith",
      title: "The role of fiber constituents in photoyellowing of jute and prevention of yellowing",
      degree: "Ph.D.",
      department: "Chemistry",
      year: "১৯৯৩",
      totalPage: 143,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-10",
      author: "John Roe",
      title: "Municipal finance in bd and investigation of local level initiative",
      degree: "Ph.D.",
      department: "Economics",
      year: "১৯৯২",
      totalPage: 249,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-11",
      author: "Jane Doe",
      title: "Growth and study of electrical properties of potassium dihydrogen phosphate (KDP), Ammonium dihydrogenphosphate (ADP) and mixed crystals of KDP-ADP at high temperatures",
      degree: "Ph.D.",
      department: "Physics",
      year: "১৯৯৪",
      totalPage: 90,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-12",
      author: "John Smith",
      title: "X-Ray analytical studies of trace elements in food and drink ; a nutritional assessments in bangladesh",
      degree: "Ph.D.",
      department: "Chemistry",
      year: "১৯৯৫",
      totalPage: 219,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-13",
      author: "Jane Roe",
      title: "Parametric and non-parametric analysis of multifactor repeated measurements experiments",
      degree: "Ph.D.",
      department: "Statistics",
      year: "১৯৯৬",
      totalPage: 177,
      cluePage: 83,
      softcopy: "No",
    },
    {
      accessionNo: "TPH-14",
      author: "John Doe",
      title: "Impacts of Development inputs on reproductive behaviour in bangladesh",
      degree: "Ph.D.",
      department: "Statistics",
      year: "১৯৯৬",
      totalPage: 279,
      cluePage: 83,
      softcopy: "No",
    }
  ];
  

const CardHorizontal = () => {
  const [data, setData] = useState([]);
  
  const handleViewPDF = (pdfUrl) => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    } else {
      alert("PDF not available");
    }
  };
  
  useEffect(() => {
    setTimeout(() => {
      setData(thesisData);
    }, 500);
  }, []);




  return (
    <div className="thesis-banner-container">
      


      {/* Thesis Cards */}
      <div className="thesis-banners">
        {data.length > 0 ? (
          data.map((item, index) => (
            <div className="thesis-banner" key={index}>
              <div className="banner-icon">
                <FaBook className="icon" />
                <span className="serial-number">#{index + 1}</span>
              </div>
              <div className="banner-content">
                <h3 className="banner-title">{item.title}</h3>
                <div className="info-box">Author: {item.author}</div>
                <div className="info-box">Degree: {item.degree}</div>
                <div className="info-box">Department: {item.department}</div>
                <div className="info-box">Total Pages: {item.totalPage}</div>
                <div className="info-box">Accession No: {item.accessionNo}</div>
                <div className="info-box">Total Pages: {item.totalPage}</div>
                <div className="info-box">Clue Page: {item.cluePage}</div>
              </div>
              <div className="pdf-container">
                {item.softcopy === "Yes" ? (
                  <button
                    className="view-pdf-btn"
                    onClick={() => handleViewPDF(item.softcopy)}
                  >
                    <FaFilePdf className="pdf-icon" /> View PDF
                  </button>
                ) : (
                  <span className="pdf-not-available">PDF not available</span>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="loading">Loading data...</p>
        )}
      </div>
    </div>
  );
};
export default CardHorizontal;
