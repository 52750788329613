// PageTitleBanner.js
import React from 'react';
import './PageTitleBanner.style.scss';

const PageTitleBanner = ({ backgroundImage, titleText }) => {
  return (
    <div 
      className="page-title-banner" 
      style={{ backgroundImage: `url(${backgroundImage})` }} 
    >
      <h1 className="title">{titleText}</h1> 
    </div>
  );
};

export default PageTitleBanner;
