import { Carousel } from 'antd';
import React from 'react';
import './image-carousel.styles.scss';

const ImageCarousel = ({ images }) => {
	return (
		<div className='image-carousel' >
			<Carousel arrows autoplay={true} fade={true} easing='linear'>
				{images.map((image) => {
					return (
						<div>
							<img
								className='imagesList'
								alt=''
								src={image.url}
								
							/>
						</div>
					);
				})}
			</Carousel>
		</div>
	);
};

export default ImageCarousel;
