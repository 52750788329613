import React, { Fragment } from 'react';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import LibraryHours from '../../components/library_hours/LibraryHours.component';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';
const timings = [
	{ day: 'Sunday', time: '7:30 AM - 9:30 PM' },
	{ day: 'Monday', time: '7:30 AM - 9:30 PM' },
	{ day: 'Tuesday', time: '7:30 AM - 9:30 PM' },
	{ day: 'Wednesday', time: '7:30 AM - 9:30 PM' },
	{ day: 'Thursday', time: '7:30 AM - 9:30 PM' },
	{ day: 'Friday', time: '3:00 PM - 9:30 PM' },
	{ day: 'Saturday', time: '9:00 PM - 9:30 PM' },
  ];

const LibraryHourPage = () => (
	<Fragment>
	<PageTitleBanner 
	backgroundImage={IMAGES.banner1}
	titleText="- Library Hours -"
	/>
	<LibraryHours
    libraryTime={timings}
    />
	</Fragment>
);

export default LibraryHourPage;
