import React from "react";
import "./privacyPolicy.style.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy__title">Privacy Policy for JU Library</h1>
      <p className="privacy-policy__effective-date">
        <strong>Effective Date:</strong> 28 October, 2024
      </p>

      <section className="privacy-policy__section">
        <h2>1. Introduction</h2>
        <p>
          Jahangirnagar University ("we," "us," or "our") operates the JU Library app to provide
          students, faculty, and staff access to library resources, services, and information. Your
          privacy is important to us, and this Privacy Policy explains our practices regarding data
          collection, which, in this case, is minimal.
        </p>
        <p>By using the App, you agree to the terms and conditions of this Privacy Policy.</p>
      </section>

      <section className="privacy-policy__section">
        <h2>2. Information We Collect</h2>
        <p>
          The JU Library app does <strong>not</strong> collect any personal information, usage data,
          or device information. We prioritize your privacy and ensure that you can access library
          resources without sharing any data.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>3. How We Use Your Information</h2>
        <p>
          Since no personal or usage information is collected, we do not use any data for personalized experiences, communication, or analytics.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>4. Information Sharing and Disclosure</h2>
        <p>
          As we do not collect any information, we have no data to share or disclose with third parties. Your activity within the app remains private.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>5. Data Security</h2>
        <p>
          The app is designed to function without storing or transmitting personal information. However, please be aware that using any online service carries inherent security risks.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>6. Data Retention</h2>
        <p>
          Since no data is collected, there is no retention of personal information within the JU Library app.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>7. Children’s Privacy</h2>
        <p>
          The JU Library app is intended for university students, faculty, and staff. We do not knowingly collect information from any users, including individuals under 13 years of age.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>8. Your Rights</h2>
        <p>
          As no personal information is collected, there are no data-related rights or requests applicable to the JU Library app.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Continued use of the App following the posting of changes indicates acceptance of those changes.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2>10. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p>
          <strong>Email:</strong> library@juniv.edu<br />
          <strong>Address:</strong> Jahangirnagar University, Savar, Dhaka
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
