import React, { Fragment } from 'react';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import NoticeBoard from '../../components/notice-board/NoticeBoard.component';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';

const allNotices = [
    {
      id: 1,
      title: "EEE Class Routine-Fall'24 Version 5.0",
      department: "Electrical and Electronic Engineering",
      date: "2024-10-21",
      views: 1020,
    },
    {
      id: 2,
      title: "SWE Class Routine Fall 2024",
      department: "Software Engineering",
      date: "2024-10-21",
      views: 1109,
    },
    {
      id: 3,
      title: "Fall 2024 Class Routine - Day V - Full and Final",
      department: "Computer Science and Engineering",
      date: "2024-10-18",
      views: 1118,
    },
    {
      id: 4,
      title: "Notice on Resources and Guidelines",
      department: "Registrar Office",
      date: "2024-10-17",
      views: 1493,
    },
    {
      id: 5,
      title: "Room Wise Student List - Midterm Defense (34th Batch)",
      department: "Software Engineering",
      date: "2024-10-16",
      views: 1117,
    },
    {
      id: 6,
      title: "Improvement/Overlap Exam Pipeline of Final Examination, Summer 2024",
      department: "Electrical and Electronic Engineering",
      date: "2024-10-16",
      views: 1051,
    },
    {
      id: 7,
      title: "Overlap/Make-up Exam of Mid-Term, Fall 2024",
      department: "Electrical and Electronic Engineering",
      date: "2024-10-16",
      views: 1062,
    }
  ];


const NoticePage = () => (
	<Fragment>
	<PageTitleBanner 
	backgroundImage={IMAGES.banner2}
	titleText="- Notice Board -"
	/>
	<NoticeBoard
    notices={allNotices}
    />
	</Fragment>
);

export default NoticePage;
