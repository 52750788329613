
import React from 'react';
import './eventItem.style.scss';
const EventItem = ({ libraryName, libraryLogo, libraryLink }) => {
  return (
    <div className="event-item">
      <div className="imageSection" >
        
      <a href={libraryLink} target="_blank" rel="noopener noreferrer">
      <img src={libraryLogo} alt={libraryName} className="images" />
    </a>
     
      </div>
    </div>
  );
};

export default EventItem;
