import React, { Fragment } from 'react';
import StylishCard from '../../components/stylish-card/StylishCard.component';
const cardData = [
    {
      title: 'The Devil In Me',
      genre: 'Survivor Horror',
      bannerImage:
        'https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/1567020/capsule_616x353.jpg?t=1676560649',
      avatarImage:
        'https://static.bandainamcoent.eu/high/the-dark-pictures/the-devil-in-me/00-page-setup/tdim_header_mobile_new-keyart.jpg',
    },
    {
      title: 'Rust',
      genre: 'Wilderness',
      bannerImage:
        'https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/252490/header.jpg?t=1715591843',
      avatarImage: 'https://files.facepunch.com/lewis/1b2911b1/rust-marque.svg',
    },
    {
      title: "Garry's Mod",
      genre: 'Physics Sandbox',
      bannerImage:
        'https://shared.akamai.steamstatic.com/store_item_assets/steam/apps/4000/header.jpg?t=1710347075',
      avatarImage: 'https://files.facepunch.com/lewis/1b1311b1/gmod-logo.svg',
    },
  ];


const Home = () => (
	<Fragment>
	<StylishCard cards={cardData}
        bannerBackgroundColor='#1867a5' />
	
	</Fragment>
);

export default Home;
