
import React from 'react';
import './bannerButton.style.scss';

const BannerButton = ({ onClick, children, className }) => {
  return (
    <button onClick={onClick} className={`button ${className}`}>
      {children}
    </button>
  );
};

export default BannerButton;
