import React, { useEffect, useState } from 'react';
import { FaBuilding, FaCalendarAlt, FaFilePdf } from 'react-icons/fa'; // New icons
import './NoticeBoard.style.scss';

const NoticeBoard = ({ notices }) => {
  const [filteredNotices, setFilteredNotices] = useState(notices);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const noticesPerPage = 5;

  useEffect(() => {
    filterNotices();
  }, [searchKeyword, dateFilter]);

  const filterNotices = () => {
    let filtered = notices;

    if (searchKeyword) {
      filtered = filtered.filter((notice) =>
        notice.title.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    }

    if (dateFilter) {
      filtered = filtered.filter(
        (notice) => new Date(notice.date) >= new Date(dateFilter)
      );
    }

    setFilteredNotices(filtered);
  };

  const indexOfLastNotice = currentPage * noticesPerPage;
  const indexOfFirstNotice = indexOfLastNotice - noticesPerPage;
  const currentNotices = filteredNotices.slice(indexOfFirstNotice, indexOfLastNotice);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="notice-board-container" >
      {/* Filter box on the top right */}
      <div className="filters-box">
        <input
          type="text"
          placeholder="Search by keyword..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
        <input
          type="date"
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
        />
        <button className="search-btn">Search</button>
      </div>

      <div className="notice-board">
        <div className="notice-list">
          {currentNotices.map((notice) => (
            <div className="notice-item" key={notice.id}>
              {/* Animated Shape in the background */}
              <div className="notice-shape"></div>

              <div className="notice-header">
                <h2>{notice.title}</h2>
              </div>
              
              <div className="notice-info">
                <p>
                  <FaBuilding className="icon" /> <span>{notice.department}</span>
                </p>
                <p>
                  <FaCalendarAlt className="icon" /> <span>{notice.date}</span>
                </p>
                <button className="view-pdf-btn">
                  <FaFilePdf /> View PDF
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredNotices.length / noticesPerPage) }).map(
            (item, index) => (
              <button key={index} onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
