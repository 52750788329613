import React, { useState } from 'react';
import './opacSearch.style.scss';

import SearchIcon from '../../assets/images/banner/search.svg';

const SearchComponent = () => {
  const [query, setQuery] = useState('');
  const [searchIndex, setSearchIndex] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    const searchUrl = `https://opac.juniv.edu/cgi-bin/koha/opac-search.pl?idx=${searchIndex}&q=${encodeURIComponent(query)}&weight_search=1`;
    window.location.href = searchUrl;
  };

  return (
    <div className="search-component">
      <form onSubmit={handleSearch}>
      <select
      className="search-select"
      value={searchIndex}
      onChange={(e) => setSearchIndex(e.target.value)}
    >
      <option value="">Library catalog</option>
      <option value="ti">Title</option>
      <option value="au">Author</option>
      <option value="su">Subject</option>
      <option value="nb">ISBN</option>
      <option value="ns">ISSN</option>
      <option value="se">Series</option>
      <option value="callnum">Call Number</option>
    </select>
        <input
          type="text"
          placeholder="Search Books..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button type="submit" className="search-button">
          <img src={SearchIcon} alt="Search" />
        </button>
      </form>
      <div className="links">
        <a href="https://opac.juniv.edu/cgi-bin/koha/opac-search.pl">Advanced search</a> | 
        <a href="https://opac.juniv.edu/cgi-bin/koha/opac-authorities-home.pl">Authority search</a> | 
        <a href="https://opac.juniv.edu/cgi-bin/koha/opac-tags.pl">Tag cloud</a> | 
        <a href="https://opac.juniv.edu/cgi-bin/koha/opac-library.pl">Library</a>
      </div>
    </div>
  );
};

export default SearchComponent;
