import React, { Fragment } from 'react';
import CardContainer from '../../components/card-container/CardContainer.component';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';
const Services = () => (
	<Fragment>
	
	<PageTitleBanner 
	backgroundImage={IMAGES.banner4}
	titleText="- Services -"
	/>
	<CardContainer />

	</Fragment>
);

export default Services;
