import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer.component';
import Header from './components/header/Header.component';
import AtoZDatabase from './pages/AtoZDatabase/AtoZDatabase.page';
import CitizenCharterPage from './pages/citizenCharter/citizenCharter.page';
import Home from './pages/home/Home.page';
import LibraryHourPage from './pages/libraryHours/libraryHours.page';
import NoticePage from './pages/notice_board/noticeBoard.page';
import QuickLinks from './pages/quickLinks/QuickLinks.page';
import Services from './pages/services/Services.page';
import ThesisList from './pages/thesis/thesisList.page';

import PrivacyPolicyPage from './pages/privacyPolicy/privacyPolicy.page';
const App = () => (
  <Router>
    <div className="app">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/quick_links" element={<QuickLinks />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/a-z-database" element={<AtoZDatabase />} />
        <Route exact path="/thesis_list" element={<ThesisList />} />
        <Route exact path="/notices" element={<NoticePage />} />
        <Route exact path="/library_hours" element={<LibraryHourPage />} />
        <Route exact path="/citizen_charter" element={<CitizenCharterPage />} />
        <Route path="/remote_access_login" element={<RedirectTo url="https://my.openathens.net/?passiveLogin=false" />} />
        <Route path="/remote_access_registration" element={<RedirectTo url="https://my.openathens.net/?passiveLogin=false" />} />
        <Route path="/plagiarism" element={<RedirectTo url="https://www.ithenticate.com/" />} />
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
        
        
        </Routes>
      <Footer />
    </div>
  </Router>
);

const RedirectTo = ({ url }) => {
  window.location.href = url;
  return null;
};


export default App;
