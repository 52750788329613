import React, { Fragment } from 'react';
import CitizenCharter from '../../components/citizen_charter/CitizenCharter.component';
import IMAGES from '../../components/imagesVariable/imagesVariable';
import PageTitleBanner from '../../components/page-title-banner/PageTitleBanner.component';

const CitizenCharterPage = () => (
	<Fragment>
	<PageTitleBanner 
	backgroundImage={IMAGES.banner1}
	titleText="- Citizen Charter -"
	/>
	< CitizenCharter />
	</Fragment>
);

export default CitizenCharterPage;
